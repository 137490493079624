
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'footer-center',
  props: {
    colClass: {
      default: 'col-lg-12',
      type: String,
    },
  },
  // computed: {},
});
