import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed",
  style: {"background-image":"url('/media/illustrations/progress-hd.png')"}
}
const _hoisted_2 = { class: "w-15 mx-auto pt-10 mt-10" }
const _hoisted_3 = { class: "flex-fill mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Logo, { class: "w-100 mx-auto" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_Footer, { class: "footer mt-auto w-100 bg-transparent" })
  ]))
}