
import { defineComponent } from 'vue';
import Logo from '@/layout/header/partials/MainLogo.vue';
import Footer from '@/layout/footer/FooterCenter.vue';
import { mapActions } from 'vuex';
import { Actions as AuthActions } from '@/store/enums/AuthEnums';

export default defineComponent({
  name: 'verify-account-page',
  components: {
    Logo,
    Footer,
  },
  mounted() {
    this.clearAuthUser();
  },
  methods: {
    ...mapActions({
      clearAuthUser: AuthActions.CLEAR_AUTH_USER,
    }),
  },
});
